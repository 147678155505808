<template>
  <div class="flex min-h-screen flex-col h-full bg-white">
    <the-header></the-header>
    <router-view v-slot="slotProps">
      <component :is="slotProps.Component"></component>
    </router-view>
    <the-footer class="mt-auto"></the-footer>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
