<template>
  <div
    class="flex items-center justify-center bg-white px-4 py-8 lg:pt-[200px]"
  >
    <main
      class="mx-auto flex flex-col lg:flex-row items-center justify-center gap-8"
    >
      <div :class="pdfBase64 ? 'w-full lg:w-4/5' : 'w-full lg:w-1/2'">
        <div v-if="!pdfBase64" class="hidden lg:block">
          <img
            :src="require('/assets/images/signer_img.png')"
            alt="e-signature illustration"
            class="w-[200px] h-auto sm:w-[240px] md:w-[280px] lg:w-[320px] xl:w-[360px] 2xl:w-[420px] object-contain"
          />
        </div>
        <div v-else class="w-full">
          <pdf-viewer
            :pdfBase64="pdfBase64"
            :pdfName="pdfName"
            @download="downloadOriginalPDF"
          />
        </div>
      </div>

      <!-- Content Section -->
      <div
        class="w-full lg:w-1/2 flex flex-col items-center lg:items-start max-w-md"
      >
        <!-- Title and Description -->
        <div class="mb-6 w-full">
          <div class="flex items-center gap-2 mb-3">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-4 lg:w-5"
            />
            <h2 class="text-lg lg:text-xl font-medium text-black">
              {{
                !pdfStatus
                  ? 'Web3 e-signatures'
                  : pdfStatus === 'fail'
                  ? 'Document Verification failed'
                  : 'Document verified'
              }}
            </h2>
          </div>
          <p class="text-xs lg:text-sm font-light text-black">
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>

        <!-- Verification Failed Message -->
        <div v-if="pdfStatus === 'fail'" class="w-full mb-4">
          <p class="text-sm font-medium text-[#4131C6] mb-1">
            Your document cannot be verified.
          </p>
          <p class="text-xs font-medium mb-1">Reason:</p>
          <p class="text-xs">
            <span class="text-sm mx-1 text-[#4131C6]">•</span
            >{{ pdfErrorMessage }}
          </p>
        </div>

        <!-- Upload or Sign Button -->
        <div class="flex flex-col gap-4 w-full max-w-md">
          <button
            v-if="!pdfStatus"
            @click="$refs.pdfToVerifyInput.click()"
            class="w-full py-2 px-4 bg-white text-black text-sm font-medium rounded border border-gray-300 shadow-sm hover:bg-gray-50 transition-all flex items-center justify-center gap-2"
          >
            <img
              :src="require('/assets/images/add.png')"
              alt="plus"
              class="w-4"
            />
            <span>Upload PDF to verify</span>
          </button>

          <button
            v-if="pdfStatus === 'fail'"
            @click="$router.push('/sign')"
            class="w-full py-2 px-4 bg-white text-black text-sm font-medium rounded border border-gray-300 shadow-sm hover:bg-gray-50 transition-all flex items-center justify-center gap-2"
          >
            <img
              :src="require('/assets/images/warning.png')"
              alt="warning"
              class="w-4"
            />
            <span>Sign PDF</span>
          </button>

          <input
            type="file"
            @change="onPdfInput($event)"
            ref="pdfToVerifyInput"
            class="hidden"
            accept="application/pdf"
          />
        </div>

        <!-- Verification Success Message -->
        <div v-if="pdfStatus === 'success'" class="w-full mt-4">
          <p class="text-sm font-medium mb-2">
            Your document was successfully verified.
          </p>
          <p v-if="signDate" class="text-xs mb-2">
            Signature process completed at:
            <span class="font-normal">{{ signDate }}</span>
          </p>
          <p class="text-xs font-medium mb-2">Participants:</p>
          <div v-for="(signer, index) in signers" :key="index" class="mb-3">
            <div class="relative bg-white">
              <img
                :src="require('/assets/images/sign_template.png')"
                alt="sign template"
                class="w-full max-w-xs"
              />
              <div
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-[5px]"
              >
                <p>{{ signer.firstName }} {{ signer.lastName }}</p>
                <p>{{ formatDate(signer.created) }}</p>
                <p>{{ signer.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { PDFDocument } from 'pdf-lib';
import crypto from 'crypto';

export default {
  setup() {
    const utils = inject('utils');
    const pdfFile = ref(null);
    const pdfStatus = ref(null);
    const pdfHash = ref(null);
    const pdfErrorMessage = ref(null);
    const verifiablePresentations = ref([]);
    const pdfName = ref(null);
    const pdfBase64 = ref(null);
    const signers = ref([]);
    const signDate = ref(null);

    async function onPdfInput(event) {
      pdfFile.value = null;
      pdfStatus.value = null;
      pdfErrorMessage.value = null;
      pdfHash.value = null;
      verifiablePresentations.value = [];

      pdfFile.value = event.target.files[0];
      pdfName.value = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(pdfFile.value);

      reader.onload = async (e) => {
        const pdfData = e.target.result.split('base64,')[1];
        const pdfDoc = await PDFDocument.load(pdfData);
        const pdfMetadata = utils.getMetadataFromPdfDoc(pdfDoc);

        pdfDoc.setModificationDate(new Date(0));
        utils.resetPdfHeaders(pdfDoc);
        const modifiedPdfData = await pdfDoc.saveAsBase64();
        pdfBase64.value = 'data:application/pdf;base64,' + modifiedPdfData;
        pdfHash.value = crypto
          .createHash('sha256')
          .update(modifiedPdfData)
          .digest('hex');

        if (pdfMetadata && pdfMetadata.createdDate)
          signDate.value = formatDate(pdfMetadata.createdDate) || null;

        if (pdfMetadata && pdfMetadata.verifications.length > 0)
          pdfMetadata.verifications.forEach((verification) => {
            verifiablePresentations.value.push(
              verification.verifiablePresentation
            );
            signers.value = pdfMetadata.verifications.map((verification) => {
              return {
                email:
                  verification.verifiablePresentation.verifiableCredential[0]
                    .credentialSubject.email,
                created: verification.verifiablePresentation.proof.created,
                firstName: verification.firstName,
                lastName: verification.lastName,
              };
            });
          });

        if (!pdfHash.value || verifiablePresentations.value.length === 0) {
          pdfErrorMessage.value =
            'The chosen PDF is not signed. To sign it, please go to the sign section.';
          pdfStatus.value = 'fail';
          return;
        }

        for (let vp of verifiablePresentations.value) {
          const pdfHashFromVP = vp.proof.challenge.split('-')[1];
          if (pdfHashFromVP !== pdfHash.value) {
            pdfErrorMessage.value =
              'The signature on the chosen PDF is not valid. Please select another PDF file.';
            pdfStatus.value = 'fail';
            return;
          }
        }

        pdfStatus.value = 'success';
      };
    }

    function downloadOriginalPDF() {
      const byteCharacters = atob(pdfBase64.value.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const fileName = `${
        pdfName.value.split('.pdf')[0].split('.PDF')[0]
      }_original.pdf`;
      saveAs(blob, fileName);
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      return (
        date.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'UTC',
        }) + ' UTC'
      );
    }

    return {
      pdfStatus,
      pdfErrorMessage,
      onPdfInput,
      pdfBase64,
      pdfName,
      downloadOriginalPDF,
      signDate,
      signers,
      formatDate,
    };
  },
};
</script>
