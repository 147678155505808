import { rgb, StandardFonts } from 'pdf-lib';
import documentSignedImageTemplate from '/assets/images/sign_template.png';
import backgroundImage from '/assets/images/background_template.png';
import QRCode from 'qrcode';
const axios = require('axios');

const resetPdfHeaders = (pdfDoc) => {
  if (!pdfDoc) {
    console.error('pdfDoc is null or undefined');
    return;
  }

  pdfDoc.setTitle('');
  pdfDoc.setAuthor('');
  pdfDoc.setSubject('');
  pdfDoc.setKeywords([]);
  pdfDoc.setProducer('Mydid Sign');
  pdfDoc.setCreator('pdf-lib (https://github.com/Hopding/pdf-lib)');
  pdfDoc.setCreationDate(new Date(0));
  pdfDoc.setModificationDate(new Date(0));
};

const getVpsFromPdfDoc = (pdfDoc) => {
  const verifiablePresentations = [];
  const keywords = pdfDoc.getKeywords() ? pdfDoc.getKeywords().split(' ') : [];

  while (keywords.length > 0) {
    try {
      const lastKeyword = keywords.pop();
      const vp = JSON.parse(decodeURI(lastKeyword));

      if (!vp.type.includes('VerifiablePresentation')) {
        throw new Error('Not a VerifiablePresentation');
      }

      verifiablePresentations.unshift(vp);
      pdfDoc.setKeywords(keywords.join(' '));
    } catch (e) {
      break;
    }
  }

  return verifiablePresentations;
};

const getMetadataFromPdfDoc = (pdfDoc) => {
  try {
    const keywords = pdfDoc.getKeywords();
    if (!keywords) {
      console.warn('No keywords found in PDF');
      return null;
    }

    // Décoder l'URI et parser le JSON
    const decodedMetadata = decodeURIComponent(keywords);
    const metadata = JSON.parse(decodedMetadata);

    // Vérifier la structure des métadonnées
    if (
      !metadata.documentMetadata ||
      !metadata.documentMetadata.context ||
      !metadata.documentMetadata.pdfHash ||
      !Array.isArray(metadata.documentMetadata.verifications)
    ) {
      console.error('Metadata structure invalid');
      return null;
    }

    // Vérifier que le contexte est correct
    if (metadata.documentMetadata.context !== 'Mydid Signed Document') {
      console.error('Document context invalid');
      return null;
    }

    // Vérifier chaque vérification
    metadata.documentMetadata.verifications.forEach((verification, index) => {
      if (
        !verification.verifiablePresentation ||
        !verification.signatureBase64
      ) {
        console.warn(`Verification ${index} uncomplete or invalid`);
      }
    });

    return metadata.documentMetadata;
  } catch (e) {
    console.error('Error while extracting metadata :', e);
    return null;
  }
};

async function generateQRCode(url) {
  const qrCodeDataURL = await QRCode.toDataURL(url);
  return qrCodeDataURL.split(',')[1];
}

async function addSignaturePage(pdfDoc, signersEmails) {
  let page = pdfDoc.addPage();
  const { width, height } = page.getSize();
  const fontSize = 12;

  // Load fonts
  const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Load and embed the template image
  const imageBytes = await fetch(documentSignedImageTemplate).then((res) =>
    res.arrayBuffer()
  );
  const image = await pdfDoc.embedPng(imageBytes);
  const imageDims = image.scale(0.3);

  let yPosition = height - 180; // Start from top of the page

  // Add template image to background of the last page
  const imageBytesBackground = await fetch(backgroundImage).then((res) =>
    res.arrayBuffer()
  );
  const imageBackground = await pdfDoc.embedPng(imageBytesBackground);
  page.drawImage(imageBackground, {
    x: 0,
    y: 0,
    width: width,
    height: height,
  });

  for (let signer of signersEmails) {
    // Draw the template image for each signer
    page.drawImage(image, {
      x: 100,
      y: yPosition - imageDims.height,
      width: imageDims.width,
      height: imageDims.height,
    });

    // Generate and add QR code
    const qrCodeUrl = `${process.env.VUE_APP_BASE_URL}/verify`;
    const qrCodeImageData = await generateQRCode(qrCodeUrl);
    const qrCodeImage = await pdfDoc.embedPng(
      Buffer.from(qrCodeImageData, 'base64')
    );
    const qrCodeDims = qrCodeImage.scale(0.5);

    page.drawImage(qrCodeImage, {
      x: width - qrCodeDims.width - 80,
      y: 90,
      width: qrCodeDims.width,
      height: qrCodeDims.height,
    });

    // Draw signer information on top of the template
    const signerInfo = [
      `Email: ${signer.email}`,
      `first Name: ${signer.firstName}`,
      `Last Name: ${signer.lastName}`,
    ];
    signerInfo.forEach((line, index) => {
      page.drawText(line, {
        x: 200,
        y: yPosition - 30 - index * 15,
        size: fontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
      });
    });

    yPosition -= imageDims.height + 10; // Move down for next signer, add some space

    page.drawText('Signed using myDid.Sign', {
      x: 150,
      y: 209,
      size: 13,
      font: boldFont,
      color: rgb(148 / 255, 173 / 255, 38 / 255),
    });

    // Add a new page if we're running out of space
    if (yPosition < 50) {
      page = pdfDoc.addPage();
      yPosition = height - 20;
    }
  }

  return pdfDoc;
}

export default {
  async install(app) {
    const utils = {
      resetPdfHeaders,
      getVpsFromPdfDoc,
      addSignaturePage,
      getMetadataFromPdfDoc,
    };
    app.provide('utils', utils);
  },
};
