<template>
  <div
    class="flex items-center justify-center bg-white px-4 py-8 lg:pt-[200px]"
  >
    <main
      class="mx-auto flex flex-col lg:flex-row items-center justify-center gap-8"
    >
      <div class="w-full hidden lg:block lg:w-1/2">
        <img
          :src="require('/assets/images/signer_img.png')"
          alt="e-signature illustration"
          class="w-[200px] h-auto sm:w-[240px] md:w-[280px] lg:w-[320px] xl:w-[360px] 2xl:w-[420px] object-contain"
        />
      </div>

      <!-- Content Section -->
      <div
        class="w-full lg:w-1/2 flex flex-col items-center lg:items-start max-w-md"
      >
        <!-- Title and Description -->
        <div class="mb-6 w-full">
          <div class="flex items-center gap-2 mb-3">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-4 lg:w-5"
            />
            <h2 class="text-lg lg:text-xl font-medium text-black">
              {{
                !alreadySigned ? 'Web3 e-signatures' : 'Document already signed'
              }}
            </h2>
          </div>
          <p class="text-xs lg:text-sm font-light text-black">
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>

        <!-- Upload Section -->
        <div class="flex flex-col gap-4 w-full max-w-md">
          <div
            v-if="
              (!pdfHash && !pdfBase64 && !originalPdfBase64) || alreadySigned
            "
            class="w-full"
          >
            <div v-if="pdfMessage && alreadySigned" class="mb-4">
              <p class="text-sm font-medium text-[#4131C6] mb-1">
                Your document cannot be signed.
              </p>
              <p class="text-xs font-medium mb-1">Reason:</p>
              <p class="text-xs">
                <span class="text-sm mx-1 text-[#4131C6]">•</span
                >{{ pdfMessage }}
              </p>
            </div>
            <button
              @click="$refs.pdfToSignInput.click()"
              :class="alreadySigned ? 'border-red-300' : 'border-gray-300'"
              class="w-full py-2 px-4 bg-white text-black text-sm font-medium rounded border shadow-sm hover:bg-gray-50 transition-all flex items-center justify-center gap-2"
              :disabled="loading"
            >
              <img
                :src="require('/assets/images/add.png')"
                alt="plus"
                class="w-4"
              />
              <span>{{ loading ? 'Loading...' : 'Upload PDF to sign' }}</span>
            </button>
            <input
              type="file"
              @change="onPdfInput($event)"
              ref="pdfToSignInput"
              class="hidden"
              accept="application/pdf"
            />
          </div>

          <!-- PDF Uploaded Section -->
          <div v-if="originalPdfBase64" class="w-full">
            <p
              v-if="!alreadySigned"
              class="text-xs text-center text-black mb-2"
            >
              {{
                pdfName.length > 50
                  ? pdfName.slice(0, 10) + '...' + pdfName.slice(-10)
                  : pdfName
              }}
            </p>
            <div v-if="!alreadySigned && !loading" class="space-y-4">
              <!-- Sender Information -->
              <div>
                <p class="text-sm font-bold mb-2">Your information</p>
                <div class="flex flex-col gap-2">
                  <input
                    type="text"
                    placeholder="First Name"
                    class="w-full text-xs p-2 border rounded"
                    v-model="firstName"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="w-full text-xs p-2 border rounded"
                    v-model="lastName"
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    class="w-full text-xs p-2 border rounded"
                    v-model="email"
                  />
                  <div class="flex items-center gap-2">
                    <input
                      type="checkbox"
                      class="cursor-pointer"
                      v-model="emailIsASigner"
                    />
                    <p class="text-xs">I'm also a signer</p>
                  </div>
                </div>
                <p class="text-xs text-gray-400 mt-1">
                  Your email will be used for updates. Check the box to be a
                  signer.
                </p>
              </div>

              <!-- Signers Section -->
              <div>
                <p class="text-sm font-bold mb-2">Signers</p>
                <div
                  v-for="(signer, index) in signers"
                  :key="index"
                  class="mb-3 relative"
                >
                  <div class="flex flex-col gap-2">
                    <input
                      type="text"
                      :placeholder="`First Name for signer ${index + 1}`"
                      class="w-full text-xs p-2 border rounded"
                      v-model="signer.firstName"
                    />
                    <input
                      type="text"
                      :placeholder="`Last Name for signer ${index + 1}`"
                      class="w-full text-xs p-2 border rounded"
                      v-model="signer.lastName"
                    />
                    <input
                      type="text"
                      :placeholder="`Email for signer ${index + 1}`"
                      class="w-full text-xs p-2 border rounded"
                      v-model="signer.email"
                    />
                  </div>
                  <button
                    @click="removeSigner(index)"
                    class="absolute top-0 right-0 text-sm text-gray-500"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                  <p
                    v-if="signer.error"
                    class="text-xs text-red-700 italic mt-1"
                  >
                    {{ signer.error }}
                  </p>
                </div>
                <button
                  v-if="signers.length < 10"
                  @click="addSigner"
                  class="w-full py-2 text-xs bg-gray-100 rounded border border-gray-300 cursor-pointer"
                >
                  <i class="fas fa-plus mr-1"></i>Add signer
                </button>
              </div>

              <button
                :class="[
                  'w-full py-2 rounded text-sm font-bold',
                  loading
                    ? 'bg-gray-400 text-gray-700'
                    : 'bg-primaryGradient text-white',
                ]"
                @click="createSession"
                :disabled="loading"
              >
                <span class="flex items-center justify-center gap-2">
                  <img
                    :src="require('/assets/images/send_icon.png')"
                    alt="send"
                    class="w-4"
                  />
                  {{ loading ? 'Processing...' : 'Send' }}
                </span>
              </button>
              <p
                v-if="errorMessage"
                class="text-xs text-center text-red-700 italic"
              >
                {{ errorMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div v-if="loading" class="text-center flex justify-center items-center">
      <custom-loader color="#D0C4E7" size="40px" class="mb-2"></custom-loader>
      <p class="text-secondary text-sm">{{ loadingMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { PDFDocument } from 'pdf-lib';
import crypto from 'crypto';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const router = useRouter();
    const pdfFile = ref(null);
    const pdfMessage = ref(null);
    const pdfBase64 = ref(null);
    const originalPdfBase64 = ref(null);
    const pdfHash = ref(null);
    const pdfName = ref(null);
    const pdfDoc = ref(null);
    const pdfViewer = ref(null);
    const loading = ref(false);
    const loadingMessage = ref('Please wait...');
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const signers = ref([]);
    const emailIsASigner = ref(true);
    const errorMessage = ref(null);
    const alreadySigned = ref(false);

    // first signer form fields
    signers.value.push({
      firstName: '',
      lastName: '',
      email: '',
      error: null,
    });
    const formattedSigners = computed(() => {
      return [
        ...signers.value.map((signer) => ({
          firstName: signer.firstName,
          lastName: signer.lastName,
          email: signer.email,
        })),
        ...(emailIsASigner.value
          ? [
              {
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
              },
            ]
          : []),
      ];
    });

    function addSigner() {
      signers.value.push({
        firstName: '',
        lastName: '',
        email: '',
        error: null,
      });
    }

    function removeSigner(index) {
      signers.value.splice(index, 1);
    }

    async function createSession() {
      if (formattedSigners.value.length == 0) {
        errorMessage.value = 'Please add signers';
        return;
      }

      if (
        formattedSigners.value.length !=
        new Set(formattedSigners.value.map((item) => item.email)).size
      ) {
        errorMessage.value = 'Duplicate emails found';
        return;
      }

      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailRegex.test(email.value)) {
        errorMessage.value = 'Invalid or missing email';
        return;
      }

      let valid = true;
      signers.value.forEach((signer, index) => {
        if (!emailRegex.test(signer.email)) {
          signer.error = `Invalid or missing email`;
          valid = false;
        } else if (
          !signer.firstName ||
          signer.firstName.length < 2 ||
          !signer.lastName ||
          signer.lastName.length < 2
        ) {
          signer.error = `Invalid or missing firstname or lastname`;
          valid = false;
        } else {
          signer.error = null;
        }
      });

      if (!valid) {
        return;
      }

      if (
        !firstName.value ||
        firstName.value.length < 2 ||
        !lastName.value ||
        lastName.value.length < 2
      ) {
        errorMessage.value = 'Missing or invalid firstname or lastname';
        return;
      }

      if (!pdfBase64.value) {
        errorMessage.value = 'Missing or invalid PDF document';
        return;
      }

      loading.value = true;
      loadingMessage.value = 'Creating session...';
      errorMessage.value = null;

      try {
        utils.resetPdfHeaders(pdfDoc.value);
        const updatedPdfDoc = await utils.addSignaturePage(
          pdfDoc.value,
          formattedSigners.value,
          new Date().toLocaleDateString()
        );

        const pdfBase64Data = await updatedPdfDoc.saveAsBase64();
        pdfBase64.value = 'data:application/pdf;base64,' + pdfBase64Data;
        pdfHash.value = crypto
          .createHash('sha256')
          .update(pdfBase64.value.split('base64,')[1])
          .digest('hex');

        const response = await api.createSession({
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
          signers: formattedSigners.value,
          pdfData: pdfBase64.value,
          originalPdfData: originalPdfBase64.value,
          pdfHash: pdfHash.value,
          pdfName: pdfName.value,
        });

        const sessionId = response.data.id;
        loading.value = false;
        router.push({
          path: '/session/' + sessionId,
          query: { email: email.value },
        });
      } catch (error) {
        loading.value = false;
        errorMessage.value =
          'An error occurred while creating the session. Please try again.';
        console.error('Session creation error:', error);
      }
    }

    async function onPdfInput(event) {
      loading.value = true;
      loadingMessage.value = 'Loading PDF...';
      pdfFile.value = null;
      pdfMessage.value = null;
      if (event.target.files[0]['type'] != 'application/pdf') {
        pdfMessage.value = 'Please select a PDF file';
        loading.value = false;
        return;
      }
      pdfFile.value = event.target.files[0];
      pdfMessage.value = event.target.files[0].name;
      pdfName.value = event.target.files[0].name;

      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          originalPdfBase64.value = e.target.result;
          const pdfData = e.target.result.split('base64,')[1];
          pdfDoc.value = await PDFDocument.load(pdfData);

          const metadata = utils.getMetadataFromPdfDoc(pdfDoc.value);
          if (metadata && metadata.verifications.length > 0) {
            alreadySigned.value = true;
            pdfMessage.value =
              'The chosen PDF file is already signed. Please select another PDF file.';
          } else {
            alreadySigned.value = false;
            pdfMessage.value = null;
          }

          const pdfBase64Data = await pdfDoc.value.saveAsBase64();
          pdfBase64.value = 'data:application/pdf;base64,' + pdfBase64Data;
          loading.value = false;
        };
        reader.onerror = (error) => {
          console.error('Error reading PDF:', error);
          pdfMessage.value = 'Error reading PDF. Please try again.';
          loading.value = false;
        };
        reader.readAsDataURL(pdfFile.value);
      } catch (error) {
        console.error('Error processing PDF:', error);
        pdfMessage.value = 'Error processing PDF. Please try again.';
        loading.value = false;
      }
    }

    return {
      pdfMessage,
      pdfHash,
      pdfBase64,
      pdfViewer,
      originalPdfBase64,
      firstName,
      lastName,
      email,
      signers,
      emailIsASigner,
      errorMessage,
      createSession,
      onPdfInput,
      addSigner,
      removeSigner,
      alreadySigned,
      pdfName,
      loading,
      loadingMessage,
    };
  },
};
</script>
