import { createApp } from 'vue';

import './styles.css';
import '@fortawesome/fontawesome-free/js/all.js';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import QrCodeVue from 'qrcode.vue';
import VuePdfEmbed from 'vue-pdf-embed';
import VueMatomo from 'vue-matomo';
import PdfViewer from '@/components/PdfViewer.vue';
import router from './router.js';
import api from './plugins/api.js';
import App from './App.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import UserSignin from './components/UserSignin.vue';
import utils from '@/plugins/utils';

const app = createApp(App);

app.use(router);
app.use(api);
app.use(VueMatomo, {
  host: 'https://mamoto.mydid.com',
  siteId: 13,
  router,
  trackerUrl: 'https://mamoto.mydid.com/mydid-00000.php',
  trackerScriptUrl: 'https://mamoto.mydid.com/javascript.php',
  requireConsent: true,
});

app.component('the-header', Header);
app.component('the-footer', Footer);
app.component('custom-loader', BounceLoader);
app.component('pdf-viewer', PdfViewer);
app.use(utils);
app.component('user-signin', UserSignin);
app.component('qrcode-vue', QrCodeVue);
app.component('vue-pdf-embed', VuePdfEmbed);

app.mount('#app');
