<script setup></script>

<template>
  <footer class="w-full flex flex-col sm:flex-row justify-center items-center gap-4 mx-auto p-3 text-xs">
    <div class="flex items-center justify-center space-x-2">
      <img
        :src="require('/assets/images/logo_comect.png')"
        alt="Logo"
        class="w-4 h-4 rounded-full"
      />
      <p class="text-black">myDidSign © 2024 Built by COMECT</p>
    </div>
    <div class="flex justify-center space-x-2">
      <a
        href="https://mydid.com/legal/terms-us.html"
        target="_blank"
        class="text-blue-400 hover:underline"
        >Terms of Service</a
      >
      <span class="text-gray-400">|</span>
      <a
        href="https://mydid.com/legal/privacy-us.html"
        target="_blank"
        class="text-blue-400 hover:underline"
        >Privacy</a
      >
      <span class="text-gray-400">|</span>
      <a
        href="https://mydid.com/legal/cookies-us.html"
        target="_blank"
        class="text-blue-400 hover:underline"
        >Data Policy</a
      >
    </div>
  </footer>
</template>

<style scoped></style>