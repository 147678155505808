import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home';
import Sign from './views/Sign';
import Verify from './views/Verify';
import Session from './views/Session';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/sign',
      component: Sign,
    },
    {
      path: '/verify',
      component: Verify,
    },
    {
      path: '/session/:id',
      component: Session,
    },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

export default router;
