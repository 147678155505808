<template>
  <div
    class="flex items-center justify-center bg-white px-4 py-8 lg:pt-[200px]"
  >
    <main
      class="mx-auto flex flex-col lg:flex-row items-center justify-center gap-8"
    >
      <div class="w-full hidden lg:block items-center  lg:w-1/2">
        <img
          :src="require('/assets/images/signer_img.png')"
          alt="e-signature illustration"
          class="w-[200px] h-auto sm:w-[240px] md:w-[280px] lg:w-[320px] xl:w-[360px] 2xl:w-[420px] object-contain"
        />
      </div>
      <div
        class="w-full lg:w-1/2 flex flex-col items-center lg:items-start max-w-md"
      >
        <div class="text-center lg:text-left mb-6">
          <div class="flex items-center justify-center lg:justify-start mb-3">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-3 sm:w-4 lg:w-5 mr-2"
            />
            <h2 class="text-lg sm:text-xl lg:text-2xl font-medium text-black">
              Web3 e-signatures
            </h2>
          </div>
          <p class="text-xs sm:text-sm lg:text-base font-light text-black">
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>
        <div class="flex flex-col gap-3 w-full">
          <router-link to="/sign" class="w-full">
            <button
              class="w-full flex items-center justify-center space-x-2 border border-gray-300 px-3 py-2 sm:py-2.5 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
            >
              <img
                :src="require('/assets/images/File_Check.png')"
                alt="File_Check.png"
                class="w-3 sm:w-4"
              />
              <span class="text-xs sm:text-sm">Sign a document</span>
            </button>
          </router-link>
          <router-link to="/verify" class="w-full">
            <button
              class="w-full flex items-center justify-center space-x-2 border border-gray-300 px-3 py-2 sm:py-2.5 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
            >
              <img
                :src="require('/assets/images/warning.png')"
                alt="File_Check.png"
                class="w-3 sm:w-4"
              />
              <span class="text-xs sm:text-sm">Verify a document</span>
            </button>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // Votre logique de composant ici
};
</script>
